import React from 'react';
import { NavBar } from './components/NavBar';
import { Banner } from './components/Banner';
import { Commissions } from './components/Commissions';
import { Emboridery } from './components/Embroidery';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';

function App() {
  return (
    <div className='bg-[#6FFFB9] text-black'>
      <main>
        <NavBar />
        <Banner />
        <Commissions />
        <Emboridery />
        <Contact />
        <Footer />
      </main>
    </div>
  );
}

export default App;
