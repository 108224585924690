import { Pagination, Navigation } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import pattern1 from '../assests/Embroidery 1.jpg'
import pattern2 from '../assests/Embroidery 2.jpg'
import pattern3 from '../assests/Embroidery 3.jpg'
import pattern4 from '../assests/Embroidery 4.jpg'
import pattern5 from '../assests/Embroidery 5.jpg'

export function Emboridery() {
    return (
        <section id='embroidery' className="py-20">
            <div className="grid grid-cols-1 place-items-center md:grid-cols-2">
                <div className="flex aspect-square rounded-2xl text-center bg-[#699B7F] w-[20.5rem] md:w-[28rem] h-[14.5rem] md:h-72 mx-10 my-10">
                    <div className="self-center font-bold text-3xl text-[#E9FEF2]">
                        Elevate Your Costumes with Custom Embroidery
                    </div>
                </div>
                <div className="flex aspect-square rounded-2xl bg-[#386C5F] w-[22.5rem] md:w-[30.5rem] h-[18.5rem] md:h-[20.5rem] mx-10 my-10 pr-2">
                    <Swiper className="aspect-square rounded-lg bg-slate-100 w-[30rem] md:w-[40rem] h-72 md:h-80 mb-16"
                        
                        // install Swiper modules
                            modules={[Pagination, Navigation]}
                            spaceBetween={40}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                        >
                            <SwiperSlide className='mb-16'>
                                <img className='object-scale-down h-72 w-[30rem]' src={pattern1} alt='Costume'/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className='object-scale-down h-72 w-[30rem]' src={pattern2} alt='Costume'/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className='object-scale-down h-72 w-[30rem]' src={pattern3} alt='Costume'/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className='object-scale-down h-72 w-[30rem]' src={pattern4} alt='Costume'/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className='object-scale-down h-72 w-[30rem]' src={pattern5} alt='Costume'/>
                            </SwiperSlide>
                        </Swiper>
                </div>
                <div className='flex md:col-start-2'>
                    <button className='w-32 h-10 inline-block bg-[#699B7F]  rounded-3xl cursor-pointer border border-black font-semibold hover:bg-slate-100'>
                        <a href="https://alter-ego-creative.myshopify.com" target='_blank' rel='noreferrer'>Commissions</a>
                    </button>
                </div>
            </div>
        </section>
    )
}