import { useRef } from 'react';
import emailjs from '@emailjs/browser';

export function Contact() {

    const form = useRef();

    const sendEmail = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        emailjs.sendForm('service_bcoquit', 'template_x5yshtb', form.current, '5l0yQXPFfrTiRq35V')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.currentTarget.reset()
    };

    //add commissions open/closed section

    return (
        <section id='contact' className="py-10">
            <div className='flex items-center justify-center pb-10 font-bold text-3xl'>
                <h1>
                    Open to Commissions!
                </h1>
            </div>
            <div className="grid grid-cols-1 place-items-center md:place-items-end md:grid-cols-2 gap-5">
                <div className="pb-16">
                    <article className="flex flex-col items-center justify-center font-sans aspect-square rounded-2xl bg-[#699B7F] w-[18rem] md:w-[24.5rem] h-[14rem] md:h-[16.5rem]">
                        <h4>Email</h4>
                        <h5>terranellcreative@gmail.com</h5>
                        <small>Available Anytime!</small><br/>
                        <a href="https://mail.google.com/mail/?view=cm&to=terranellcreative@gmail.com" target="_blank" rel='noreferrer' className='underline hover:text-slate-100'>Send Me An Email!</a>
                    </article>
                </div>
                {/* END OF CONTACT OPTIONS */}
                <form ref={form} onSubmit={sendEmail} className="flex flex-col gap-[1.2rem] items-center md:items-start justify-center font-sans md:mr-auto">
                    <input className="w-96 p-[1.5rem] rounded-2xl bg-slate-100 border border-black" type="text" name='customer_name' placeholder='Your Full Name' required />
                    <input className="w-full p-[1.5rem] rounded-2xl bg-slate-100 border border-black" type="email" name='customer_email' placeholder='Your Email' required />
                    <textarea className="w-full p-[1.5rem] rounded-2xl bg-slate-100 border border-black" name="customer_message" placeholder='Your message' required></textarea>
                    <button className="w-32 h-10 inline-block bg-[#699B7F]  rounded-3xl cursor-pointer border border-black font-semibold hover:bg-slate-100" type='submit'>Submit</button>
                </form>
            </div>
        </section>
    )
}