import LogoNoWords from '../assests/logoNoWordsTouchUp.png'

export function NavBar() {
    return (
        <nav>
        <div className='flex place-content-center pt-4 md:grid md:grid-cols-2'>
            <div className='md:flex md:items-end md:justify-end'>
                {/* eslint-disable-next-line */}
                <a href='#'>
                    <img className="w-32 h-32 flex-shrink-0 hover:cursor-pointer"src={LogoNoWords} alt='Logo' />
                </a>
            </div>
            <div className="hidden md:flex md:items-center md:justify-start" id="navbar-default">
                <ul className="text-xl md:text-2xl md:pt-4 md:grid md:grid-flow-col md:auto-cols-auto md:gap-4">
                    <li>
                        <a href="#commissions">Commissions</a>
                    </li>
                    <li>
                        <a href="#embroidery">Embroidery</a>
                    </li>
                    <li>
                        <a href="#contact">Contact</a>
                    </li>
                </ul>
            </div>
        </div>
        </nav>
    )
}