import LogoWords from '../assests/logoWordsTouchUp.png'

export function Footer() {
    return (
        <section className="py-10 bg-[#386C5F]">
        <nav>
            <div className="flex p-10">
                <div className="m-auto" id="navbar-default">
                <ul className="font-medium flex items-center space-x-8 text-[#F6DF5E]">
                    <li>
                    <a href="#commissions">Commissions</a>
                    </li>
                    <li>
                    <a href="#embroidery">Embroidery</a>
                    </li>
                    <li>
                    <a href="#contact">Contact</a>
                    </li>
                </ul>
                </div>
            </div>
        </nav>
        <div className='flex items-center justify-center'>
            {/* eslint-disable-next-line */}
            <a href='#'>
                <img className="w-28 h-28 hover:cursor-pointer"src={LogoWords} alt='Logo' />
            </a>
        </div>
        <div className='flex items- justify-center'>
            <small>
               Copyright &copy; 2023, Alter Ego Creative
            </small>
        </div>
        </section>
    )
}