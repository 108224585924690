import BannerHeader from '../assests/HeadingBanner.png'

export function Banner() {
    return (
        <section className='py-20'>
            <div>
                <img className='w-screen' src={BannerHeader} alt='Banner' />
            </div>
        </section>
    )
}