// import Swiper core and required modules

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import wig1 from '../assests/wig 1.jpg'
import wig2 from '../assests/wig 2.jpg'
import wig3 from '../assests/wig 3.jpg'
import wig4 from '../assests/wig 4.jpg'
import wig5 from '../assests/wig 5.jpg'

//this is a test

export function Commissions() {
    return (
        <section id='commissions' className="py-20">
            <div className="grid place-items-center justify-items-center grid-cols-1 md:grid-cols-2">
                <div className="flex aspect-square rounded-2xl text-center bg-[#699B7F] w-[20.5rem] h-[13.5rem]">
                    <div className="self-center font-bold text-3xl text-[#E9FEF2]">
                        Express Yourself with Custom Costume Creations
                    </div>
                </div>
                <div className="aspect-square rounded-2xl bg-[#386C5F] w-[20.5rem] md:w-[30.5rem] h-[15.5rem] md:h-[18.5rem] mx-10 my-10 pr-2">
                    <Swiper className="aspect-square rounded-lg bg-slate-100 w-[20rem] md:w-[30rem] h-60 md:h-72"
                        
                        // install Swiper modules
                            modules={[Pagination, Navigation]}
                            spaceBetween={40}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                        >
                            <SwiperSlide>
                                <img className='object-scale-down h-72 w-[30rem]' src={wig1} alt='Costume'/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className='object-scale-down h-72 w-[30rem]' src={wig2} alt='Costume'/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className='object-scale-down h-72 w-[30rem]' src={wig3} alt='Costume'/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className='object-scale-down h-72 w-[30rem]' src={wig4} alt='Costume'/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className='object-scale-down h-72 w-[30rem]' src={wig5} alt='Costume'/>
                            </SwiperSlide>
                        </Swiper>
                </div>
                <div className="aspect-square rounded-2xl bg-[#386C5F] w-[20.5rem] md:w-[30.5rem] h-[15.5rem] md:h-[18.5rem] mx-10 my-10 pr-2">
                    <Swiper className="aspect-square rounded-lg bg-slate-100 w-[20rem] md:w-[30rem] h-60 md:h-72"
                    // install Swiper modules
                            modules={[Pagination, Navigation]}
                            spaceBetween={40}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                        >
                            <SwiperSlide>
                                <img className='object-scale-down h-72 w-[30rem]' src={wig3} alt='Costume'/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className='object-scale-down h-72 w-[30rem]' src={wig4} alt='Costume'/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className='object-scale-down h-72 w-[30rem]' src={wig5} alt='Costume'/>
                            </SwiperSlide>
                        </Swiper>
                </div>
                <div className="flex aspect-square rounded-2xl text-center bg-[#699B7F] w-[20.5rem] h-[13.5rem]">
                    <div className="self-center font-bold text-3xl whitespace-normal p-10 text-[#E9FEF2]">
                        Embracing Inclusivity
                    </div>
                </div>
            </div>
        </section>
    )
}